/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import WhyUsPg from "pages/LandingPages/WhyUs";
import OverviewPg from "pages/LandingPages/WhyUs/Pages/Overview";
import ApproachPg from "pages/LandingPages/WhyUs/Pages/Approach";

import AchievementsPg from "pages/LandingPages/WhyUs/Pages/Achievements";
// function OverviewPage() {
//     return <Overview />;
// }

// export default function LearningPage() {
//     return <Learning />;
// }

export function WhyUs() {
    return <WhyUsPg />;
}

export function Overview() {
    return <OverviewPg />;
}

export function Approach() {
    return <ApproachPg />;
}

export function Achievements() {
    return <AchievementsPg />;
}

// export * as Overview from "pages/LandingPages/Learning/Sections/Overview";

