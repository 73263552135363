// import { Outlet } from "react-router-dom";
import React from "react";
import Box from "@mui/material/Box"
import { clsx } from 'clsx'
import getTags from "./services/getTags";
import { Grid } from 'components/grid'
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
import routes from "routes";
// import Markdown from "markdown-to-jsx";
import {matchSorter} from 'match-sorter'
import { H4, H6 } from 'components/typography'
import { ArticleCard } from 'components/article-card'
import {HeroSection} from "layouts/sections/hero-section";
import { useSearchParams, useLocation } from "react-router-dom";
import { SearchIcon } from 'components/icons'
import useArticleList from "./hooks/useArticles";
import { images } from 'images'
import {Helmet} from "react-helmet";
import 'assets/css/blog.css';
const specialQueryRegex = /(?<not>!)?leader:(?<team>\w+)(\s|$)?/g

// const PAGE_SIZE = 12
// const initialIndexToShow = PAGE_SIZE
function BlogApp() {
	const { state } = useLocation();
	const {rhash} = state || {}
	const resultsRef = React.useRef(null)
	// const [error_mesg, setErrorMesg] = React.useState("");
	// const [isLoading, setisLoading] = React.useState(true);
	// const [searchParams, setSearchParams] = React.useState("");
	const [searchParams] = useSearchParams()
	const [tags, setTags] = React.useState([]);
	const [loadingTags, setLoadingTags] = React.useState(false);
	// const [indexToShow, setIndexToShow] = React.useState(initialIndexToShow)
	const searchInputRef = React.useRef(null)
	const ignoreInputKeyUp = React.useRef(false)
	const [queryValue, setQuery] = React.useState(() => {
		return searchParams.get('q') ?? ''
	})
	const query = queryValue.trim()

	const { articles, loading } = useArticleList({
		location: 'global',
		tabName:'global',
		tagName: query,
	});

	React.useEffect(() => {
	// 	getArticles({ headers:null, limit:1000, location:"global" })
	// 		.then((response) => setAllPosts(response))
	// 		.catch((error) => {
	// 			console.error(error);
	// 			setErrorMesg("Something went wrong. Please try again.");
	// 		})
	// 		.then(function () {
	// 			setisLoading(false);
	// 		});
		if (rhash !== '') {
			toggleTag(rhash)
		}
		getTags()
			.then(setTags)
			.catch(console.error)
			.finally(() => {
				setLoadingTags(false)
			});
	}, []);
	const sortedArticles = React.useMemo(() => {
		let filteredPosts = articles
		return matchSorter(filteredPosts, 'editors', {keys: ['slug'], threshold: matchSorter.rankings.NO_MATCH})
	}, [articles,])

	// React.useEffect(() => {
	// 	setIndexToShow(initialIndexToShow)
	// }, [query])

	function toggleTag(tag) {
		setQuery((q) => {
			// create a regexp so that we can replace multiple occurrences (`react node react`)
			const expression = new RegExp(tag, 'ig')

			const newQuery = expression.test(q)
				? q.replace(expression, '')
				: `${q} ${tag}`

			// trim and remove subsequent spaces (`react   node ` => `react node`)
			return newQuery.replace(/\s+/g, ' ').trim()
		})
	}
	// if(loading || loadingTags) return (
	// 	<Box sx={{bgcolor: 'background.default',	color: 'text.primary', borderRadius: 1,	px: 0,}}>
	// 		<>
	// 			<Helmet>
	// 				<meta name="description" content="About Ekambuli High School" />
	// 				<title>Ekambuli High School Blog</title>
	// 				<meta name="og:url" content={window.location.href}></meta>
	// 				<link rel="canonical" href={window.location.href} />
	// 				<link rel="icon" href="/favicon.ico"></link>
	// 				<meta name="og:title" content="Ekambuli Secondary School Blog"></meta>
	// 				<meta name="og:description" content="Ekambuli Secondary School Blog"></meta>
	// 				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, blog"></meta>
	// 				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
	// 				<meta name="robots" content="index,follow"></meta>
	// 			</Helmet>
	// 			<DefaultNavbar
	// 				routes={routes}
	// 				relative
	// 				sticky
	// 			/>
	// 			<div>
	// 				Loading...
	// 			</div>
	// 		</>
	// 	</Box>
	// )
	// if(error_mesg!=="") return error_mesg;
	
	
	const regularQuery = query.replace(specialQueryRegex, '').trim()

	// const matchingPosts = React.useMemo(() => {
	// 	const r = new RegExp(specialQueryRegex)
	// 	let match = r.exec(query)
	// 	while (match) {
	// 		match = r.exec(query)
	// 	}

	// 	let filteredPosts = allPosts
	// 	return filterPosts(filteredPosts, regularQuery)
	// }, [
	// 	allPosts,
	// 	query,
	// 	regularQuery,
	// ])
	// const posts = matchingPosts

	// when the query changes, we want to reset the index

	// const isSearching = query.length > 0

	// const posts = isSearching
	// 	? matchingPosts.slice(0, indexToShow)
	// 	: matchingPosts.slice(0, indexToShow)


	// console.dir(filteredPosts)
	// console.dir(loadingTags)
	// console.dir(queryValue)
	// console.dir(posts)
	// console.dir(setLoadingTags)
	// console.dir(articles)
	// console.log(sortedArticles)
	return (
					// className="px-8 xs:mx-2vw lg:mx-10vw font-sans Noto-Sans -apple-system BlinkMacSystemFont Segoe-UI Roboto Helvetica-Neue Arial">
		<Box sx={{bgcolor: 'background.default', color: 'text.primary', borderRadius: 1, px: 0,}}>

				<Helmet>
					<meta name="description" content="About Ekambuli High School" />
					<title>Ekambuli High School Blog</title>
					<meta name="og:url" content={window.location.href}></meta>
					<link rel="canonical" href={window.location.href} />
					<link rel="icon" href="/favicon.ico"></link>
					<meta name="og:title" content="Ekambuli Secondary School Blog"></meta>
					<meta name="og:description" content="Ekambuli Secondary School Blog"></meta>
					<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, blog"></meta>
					<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
					<meta name="robots" content="index,follow"></meta>
				</Helmet>
				<DefaultNavbar
					routes={routes}
					relative
					sticky
				/>
				{/* <div className="mx-7vw shadow-inner pb-4 mb-0">
					<div className="mx-auto px-4">
						<H3 as="div" className="w-full my-2 text-center">EKASS</H3>
					</div>
				</div> */}
				<HeroSection
					// subtitle="Find the latest of our blog here."
					imageBuilder={images.ekassfrontpage}
					action={
						<div className="w-full">
							<form
								action="/blog"
								method="GET"
								onSubmit={(e) => e.preventDefault()}
							>
								<div className="relative">
									<button
										title={query === '' ? 'Search' : 'Clear search'}
										type="button"
										onClick={() => {
											setQuery('')
											ignoreInputKeyUp.current = true
											searchInputRef.current?.focus()
										}}
										onKeyDown={() => {
											ignoreInputKeyUp.current = true
										}}
										onKeyUp={() => {
											ignoreInputKeyUp.current = false
										}}
										className={clsx(
											'absolute left-6 top-0 flex h-full items-center justify-center border-none bg-transparent p-0 text-slate-500',
											{
												'cursor-pointer': query !== '',
												'cursor-default': query === '',
											},
										)}
									>
										<SearchIcon />
									</button>
									<input
										ref={searchInputRef}
										type="search"
										value={queryValue}
										onChange={(event) =>
											setQuery(event.currentTarget.value.toLowerCase())
										}
										onKeyUp={(e) => {
											if (!ignoreInputKeyUp.current && e.key === 'Enter') {
												resultsRef.current
													?.querySelector('a')
													?.focus({ preventScroll: true })
												resultsRef.current?.scrollIntoView({ behavior: 'smooth' })
											}
											ignoreInputKeyUp.current = false
										}}
										name="q"
										placeholder="Seach articles"
										className="text-primary bg-primary border-secondary focus:bg-secondary w-full appearance-none rounded-full border py-4 pl-14 pr-4 text-lg font-medium hover:border-team-current focus:border-team-current focus:outline-none"
									/>
								</div>
							</form>
						</div>
					}
				/>
				<Grid className="mb-4">
					{!loadingTags && tags.tags?.length > 0 ? (
						<>
							<H6 as="div" className="col-span-full my-6">
								Search blog by topics
							</H6>
							<div className="col-span-full mb-7 -mr-4 flex flex-wrap justify-end">

									{tags.tags.length > 0 ? (
										tags.tags.slice(0, 50).map((tag) => {
											const selected = regularQuery.includes(tag)
											return (
												<a href="" key={tag} className={clsx("tag-pill tag-default", 
														{
															'text-primary bg-secondary': !selected,
															'text-inverse bg-inverse': selected,
														}
													)}
													onClick={(e) => {
														e.preventDefault()
														toggleTag(tag)
													}}
												>
													{tag}
												</a>
											)
										})
									) : (
										<p>Tags list not available</p>
									)}

							</div>
						</>
					) : null}
					{ loading ? (
						<H4 as="div" className="w-full mb-6 text-center">

						</H4>
					) : (
						<>
							{sortedArticles.map((article) => (
								// <div
									// key={article.slug}
									// className={clsx('col-span-4', {
									// 	'hidden lg:block1': idx >= 2,
									// })}
								// >
									<ArticleCard key={article.slug} article={article}/>
								// </div>
							))}
						</>
					)}
				</Grid>

		</Box>
	);
}

export default BlogApp;
