/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

*/

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Images
import bgImage from "assets/images/class-20240612-1400x600.jpg";


function Overview({ refProps }) {
	return (
		<>
			<Box
				minHeight="75vh"
				ref={refProps}
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.1),
						rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					fontFamily:"cursive",
					placeItems: "center",
					paddingLeft:"25px"
				}}
			>
				<div className="text-[color:white] text-2xl md:text-3xl text-center text-bold ">
					Educational excellence in Western Kenya
				</div>
			</Box>
			<Box 
				sx={{ maxWidth: '100%', flexGrow: 1, bgcolor:"inherit" }}
				className="-mt-12 mb-20 rounded-xl mx-5vw"
			>
				<Grid container spacing={2} >
					<Grid item md={4} mt={-4}>
						<div className="rounded-lg text-3xl py-1 h-max" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.55rem .938rem 0.55rem" }}>Overview</div>
					</Grid>
					<Grid item md={8} pt={5} pl={5}>
						<div style={{ fontSize:"1.25rem"}} className="font-semibold tracking-tighter mb-7">
							Ekambuli Secondary School follows the 8-4-4 Curriculum, leveraging the unique opportunities provided by our environment and location in Kenya. 
							We adhere to the Teachers Service Commission (TSC) syllabus in all subject areas.
						</div>
						<div className="text-sch_color1 text-xl font-semibold tracking-tighter">Educational Technology</div>
						<p className="mb-5">
							Our use of educational technology is currently limited.
						</p>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Teachers</div>
						<p className="mb-5">
							We have a dedicated team of twenty (20) TSC teachers and five (5) teachers employed by the Board of Management (BOM).
						</p>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Support staff</div>
						<p className="mb-5">
							We have 30 well-trained and dedicated support staff.
						</p>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Facilities</div>
						<p className="mb-5">
							Our facilities include a library, classrooms, boarding, dining, kitchen, computer lab, science lab, school bus, electricity, piped water, co-curricular activities, and a 20-acre farm. All facilities are secured by a fence and a gate.
						</p>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Supportive Community and Partners</div>
						<p className="mb-5">
							We receive dedicated support from parents/guardians, local MP Hon. Aseka, Khwisero NGCDF, and the community.
						</p>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default Overview;