import React from 'react';
// eslint-disable-next-line
// import { Swiper, SwiperSlide } from 'swiper/react';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
import 'assets/css/swiper.css'

// eslint-disable-next-line
// import 'swiper/css';
import { Link } from "react-router-dom";
// import MKTypography from "components/MKTypography";
import Typography from '@mui/material/Typography';
import { images, getImgProps } from 'images'

const cimages = [
	{
		label: 'Drama club',
		imess: "Seize. Opportunity. Learn.",
		moreurl: "/about-us",
		labelurl: "more about us",
		imgPath: images.drama,
		// imgPath: `${bgImage0_480}, ${bgImage0_768}, ${bgImage0_1400}`,
	},
	{
		label: 'School Gate',
		imess: "",
		moreurl: "",
		labelurl: "",
		imgPath: images.gate,
	},
	{
		label: 'School Teachers',
		imess: "Be in demand.",
        // key:"news",
		moreurl: "/blog/the-sleeping-giant-awakens-akgu8ej4",
		labelurl: "the sleeping giant",
		imgPath: images.teachers,
	},
	{
		label: 'Football team',
		imess: "Be of high quality.",
        key:"achieve",
		moreurl: "/why-us",
		labelurl: "our achievements",
		imgPath: images.football,
	},
	{
		label: 'School photo',
		imess: "Jitihadi. Juhudi. Bidii.",
        // key: "Jitihadi",
		moreurl: "/blog/jitihadi-juhudi-bidii-huleta-ufanisi-fanaka-ustawi-jfks04k6m3",
		labelurl: "Jitihadi/Juhudi/Bidii",
		imgPath: images.photo,
	},
	{
		label: 'kitchen',
		imess: "Confident. Well-adjusted. Responsible.",
        // key: "leadership",
		moreurl: "/blog/student-leadership-ups8qs",
		labelurl: "student leadership",
		imgPath: images.kitchen,
	},
	{
		label: 'School farm',
		imess: "Commitment. Mission. Integrity.",
        key:"approach",
		moreurl: "/why-us",
		labelurl: "our aproach",
		imgPath: images.farm,
	},
	{
		label: 'School forest',
		imess: 'Education. Innovation. Talents.',
		moreurl: "/about-us",
		labelurl: "more about us",
		imgPath: images.forest,
	},
	{
		label: 'Teachers meeting',
		imess: 'Results. Universities. Colleges.',
		// key:"career",
		moreurl: "/blog/first-class-career-guidance-ytjsm7k2",
		labelurl: "Career guidance",
		imgPath: images.teachers,
	},
	{
		label: 'Science fair preparations',
		imess: "Discover. Self-drive. Strive.",
        // key: "KCSE",
		moreurl: "/blog/2023-Candidates-98jud6",
		labelurl: "our results",
		imgPath: images.science,
	},
	{
		label: 'Admnistration Block',
		imess: "Systems. Staff.  Trust.",
        key:"approach",
		moreurl: "/why-us",
		labelurl: "our approach",
		imgPath: images.adminblk,
	},
];


export default () => {

    return (
        <div className="h-auto max-w-full flex justify-center">
            <main className="w-full">
                {/* <swiper-container slides-per-view="2" space-between="20" scrollbar-clickable="true" mousewheel-invert="true"> */}
                {/* <swiper-container breakpoints={
                    JSON.stringify({

                        640:{
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },

                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },

                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        }
                    })
                }> */}
                <swiper-container className="image-swiper" style={{"--swiper-navigation-color": "#fff", "--swiper-pagination-bullet-inactive-color": "#999999", "--swiper-pagination-bullet-inactive-opacity": "1", "--swiper-pagination-color": "#fff", "--swiper-pagination-bullet-horizontal-gap": "16px", "--swiper-pagination-bottom": "-36px"}} 
                        space-between="10" 
                        slides-per-view="2" 
                        pagination="true" 
                        pagination-clickable="true" 
                        navigation="true" 
                        loop="true"
                        effect="cube"
                        grab-cursor="true"
                        cube-effect-shadow="true"
                        cube-effect-slide-shadows="true"
                        cube-effect-shadow-offset="20"
                        cube-effect-shadow-scale="0.94"    
                        center-slides="true"
                        autoHeight="true"
                        // parallax={true}
                    >
                    {/* <div className="parallax-bg" style={{backgroundImage:`url(${cimages[11].imgPath})`}} data-swiper-parallax="-23%"></div> */}
                    { cimages.map((klide, idx) => (
                        <swiper-slide key={idx}>
                            <img
                                {...getImgProps(klide.imgPath, {
                                    style:"",
                                    className: 'rounded-lg object-cover w-full',
                                    widths: [256, 550, 700, 900, 1300, 1800],
                                    sizes: [
                                        '(max-width: 320px) 256px',
                                        '(min-width: 321px) and (max-width: 1023px) 80vw',
                                        '(min-width: 1024px) 410px',
                                        '850px',
                                    ],
                                })}
                            />
                            <div className="bg-secondary text-center absolute flex top-[3%] justify-center w-full text-xl md:text-4xl">
                                {klide.imess}
                            </div>
                            { klide.labelurl &&
                                <div className="absolute bottom-10 left-1/2 ml-[-45%] opacity-40 flex bg-[#000] w-[90%] justify-center sm:text-xl md:text-3xl ">
                                    {/* <button className="px-2 text-white">
                                        <Link
                                            className="nav-link"
                                            to={klide.moreurl}
                                        >
                                            {klide.labelurl}
                                        </Link>
                                    </button> */}
                                    <Typography className="text-white nav-link"
                                        key={idx}
                                        component={Link}
                                        state={{rhash:klide.key || ""}}
                                        to={klide.moreurl ? klide.moreurl : ""}
                                        // href={item.href ? item.href : (e) => e.preventDefault()}
                                        // target={item.href ? "_blank" : ""}
                                        // rel={item.href ? "noreferrer" : "noreferrer"}
                                        // minWidth="11.25rem"
                                        display="block"
                                        // variant="button"
                                        color="text"
                                        textTransform="capitalize"
                                        fontWeight="regular"
                                        // py={0.625}
                                        // px={2}
                                        // sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                                        //     borderRadius: borderRadius.md,
                                        //     cursor: "pointer",
                                        //     transition: "all 300ms linear",

                                        //     "&:hover": {
                                        //         backgroundColor: grey[200],
                                        //         color: dark.main,
                                        //     },
                                        // })}
                                    >
                                        {klide.labelurl}
                                    </Typography>
                                </div>
                            }
                        </swiper-slide>
                    ))}
                </swiper-container>
            </main>
        </div>
    );
};


