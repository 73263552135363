/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

*/
import React from "react"
// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";

// import Box from "@mui/material/Box";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";

import Achievements from "./sections/Achievements";
import Overview from "./sections/Overview";
import Approach from "./sections/Approach";

import { useLocation } from "react-router-dom";

// Routes
import routes from "routes";

// Images
// import bgImage from "assets/images/class-20240612-1400x600.jpg";
import {Helmet} from "react-helmet";
// import { Overview } from "layouts/pages/landing-pages/why-us";

// export default function WhyUs() {
const WhyUs = () => {
	const { state } = useLocation();
	const {rhash} = state || {}
	const refAchieve = React.useRef();
	const refApproach = React.useRef();
	const refOvervw = React.useRef();
	// const params = useParams();
	// React.useEffect(() => {
	// 	// if not a hash link, scroll to top
	// 	if (hash === '') {
	// 		window.scrollTo(0, 0);
	// 	}
	// 	// else scroll to id
	// 	else {
	// 		setTimeout(() => {
	// 			const id = hash.replace('#', '');
	// 			const element = document.getElementById(id);
	// 			if (element) {
	// 				element.scrollIntoView();
	// 			}
	// 		}, 0);
	// 	}
	// }, [pathname, hash, key]);
	const Wrapper = ({ children }) => {
		const location = useLocation();

		React.useLayoutEffect(() => {
			if (rhash === 'approach') {
				setTimeout(() => {
					// console.log(rhash === 'approach')
					refApproach.current?.scrollIntoView({behavior: "smooth"});
					refApproach.current?.focus();
				}, 100)
			} else if(rhash === 'achieve') {
				// e.preventDefault();
				setTimeout(() => {
					// console.log(refAchieve.current)
					refAchieve.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
					refAchieve.current?.focus();
				}, 100)
			} else if(rhash === "overview") {
				// e.preventDefault();
				setTimeout(() => {
					// console.log(rhash === 'overview')
					refOvervw.current?.scrollIntoView({behavior: "smooth",
						block: "end",
						inline: "nearest",
					});
					refOvervw.current?.focus();
				}, 100)
			}
		}, [location.pathname]);
	
		return children;
	};
// console.log("rhash:",rhash)
// console.log("key:",key)
	// function scrollToComponent() {
	// const scrollToComponent = () => {
	// 	if (rhash === 'approach') {
	// 		console.log(rhash === 'approach')
	// 		refApproach.current?.scrollIntoView({behavior: "smooth"});
	// 		refApproach.current?.focus();
	// 	} else if(rhash === 'achieve') {
	// 		console.log(refAchieve.current)
	// 		// e.preventDefault();
	// 		refAchieve.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
	// 		// refAchieve.current?.focus();
	// 	} else if(rhash === "overview") {
	// 		console.log(rhash === 'overview')
	// 		// e.preventDefault();
	// 		setTimeout(() => {
	// 			refOvervw.current?.scrollIntoView({behavior: "smooth",
	// 				block: "end",
	// 				inline: "nearest",
	// 			});
	// 			refOvervw.current?.focus();
	// 		}, 100)
	// 	}
		
	// }
  
	// React.useEffect( (e) => scrollToComponent(e), [rhash, key] )
	// React.useEffect(() => scrollToComponent(), [])

	return (
		<>
            <Helmet>
				<title>Overview Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Why Us"></meta>
				<meta name="og:description" content="Why Us, Overview, Achievements, Approach"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, overview, approach, achieve"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
			</Helmet>
			<DefaultNavbar
				routes={routes}
				relative
				sticky
			/>
			<Wrapper>
				<Overview refProps={refOvervw} />
				<Approach refProps={refApproach} />
				<Achievements refProps={refAchieve} />
			</Wrapper>
		</>
	);
}
export default WhyUs
