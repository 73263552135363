/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================
*/

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import bgImage from "assets/images/volleyball-20240612-1400x600.jpg";


const Achievements = ({ refProps }) => {

	return (
		<>
			<Box 
				ref={refProps}
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.1),
						rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					fontFamily:"cursive",
					placeItems: "center",
					paddingLeft:"5px"
				}}
			>
				<div className="text-[color:white] text-3xl md:text-4xl text-center text-bold ">
					Everyone Achieves
				</div>
			</Box>
			<Box p={0} mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit" }} className="-mt-12 rounded-xl mx-5vw">
					<Grid container spacing={2} >
						<Grid item md={5} mt={-4} >
							<div className="rounded-lg text-3xl py-1" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.55rem .938rem 0.55rem" }}>Achievements</div>
						</Grid>
						<Grid item md={7} >
							<div style={{fontSize:"1.25rem"}} className="text-xl tracking-tighter mb-7">
								We are committed to delivering an education that cultivates well-rounded children, preparing them not only to seamlessly integrate into society but also to pursue further professional education. <br/><br/>
								Our mean score is 4.582. <br/>
								We take pride in our ability to send at least ten (10) students to public universities annually, while the remaining students continue their educational journey in various tertiary institutions. <br/><br/>
								We participate and compete favorably in music (Regional level), football, netball, volleyball and take part in scouting and other diverse pursuits.
							</div>
						</Grid>
					</Grid>
			</Box>
		</>
	);
}

export default Achievements;
