import axios from "axios";
import errorHandler from "../helpers/errorHandler";

async function getArticle({ headers, slug }) {
	try {
		// console.log("slug:",slug)
		const { data } = await axios({ headers, url: `/api/articles/${slug}` });
		// console.log("article:",data)
		return data.article;
	} catch (error) {
		errorHandler(error);
	}
}

export default getArticle;
