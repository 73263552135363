import { Link } from 'react-router-dom'
// import { clsx } from 'clsx'
import {formatDate} from 'utils/misc'
// import { useTheme } from '@mui/material/styles';
import { H3 } from 'components/typography'
// import { getHeroImageProps, } from 'layouts/sections/hero-section'
import { images, getImageBuilder, getImgProps } from 'images'

function ArticleCard({
	article: {
		readTime,
		updatedAt,
		slug,
		bannerId,
			// frontmatter,
		// frontmatter: {
		title = 'Untitled Post',
			// bannerCloudinaryId,
			// bannerBlurDataUrl,
		// },
	},
}) {
	// const { requestInfo } = useRootData()
	// const permalink = `${requestInfo.origin}/blog/${slug}`
    // const permalink = "..."
	// const theme = useTheme();
	// const { palette } = theme;
	// const { text } = palette;
	// console.dir(bannerId)
	return (
		<div className="col-span-4 mb-12"
			// className={clsx(
			// 	'relative w-full mb-11',
			// )}
		>
			<Link
				prefetch="intent"
				className="group peer relative block w-full focus:outline-none"
				to={`/blog/${slug}`}
			>
				{bannerId ? (
					<span key={bannerId} className="aspect-[3/4] rounded-lg">

						{/* <img
							{...getHeroImageProps(images[bannerId], {
								className:
									'rounded-bl-3xl rounded-br-[25%] rounded-tl-[25%] rounded-tr-3xl',
							})}
						/> */}


							<img
								title={title}
								{...getImgProps(
									getImageBuilder(images[bannerId].id, images[bannerId].alt,),
									{
										widths: [280, 560, 840, 1100, 1300, 1650],
										sizes: [
											'(max-width:639px) 80vw',
											'(min-width:640px) and (max-width:1023px) 40vw',
											'(min-width:1024px) and (max-width:1620px) 25vw',
											'420px',
										],
										transformations: {
											background: 'rgb:e6e9ee',
											resize: {
												type: 'fill',
												aspectRatio: '3:4',
											},
										},
									},
								)}
								className="focus-ring w-full rounded-lg object-cover object-center transition"
								loading="lazy"
							/>

					</span>
				) : (
					<div className="aspect-[3/4]">
						<div className="focus-ring w-full rounded-lg transition">
							
						</div>
					</div>
				)}

				<div className="text-xl font-medium text-gray-500">
					{[formatDate(updatedAt), readTime ?? 'quick read']
						.filter(Boolean)
						.join(' — ')}
				</div>
				<H3 as="div" className="text-2xl font-medium md:text-3xl text-black dark:text-white">
					{title}
				</H3>
			</Link>
		</div>
	)
}

export { ArticleCard }