import React from "react";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import ArticleMeta from "../../components/ArticleMeta";
// import ArticlesButtons from "../../components/ArticlesButtons";
import { H2, H6 } from 'components/typography'
import Box from "@mui/material/Box"
import ArticleTags from "../../components/ArticleTags";
// import BannerContainer from "../../components/BannerContainer";
import { useAuth } from "../../context/AuthContext";
import getArticle from "../../services/getArticle";
import {Spinner} from "components/spinner"
import * as mdxBundler from 'mdx-bundler/client/index.js'
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
import {Helmet} from "react-helmet";
import routes from "routes";
import {formatDate} from 'utils/misc'
import { images, getImageBuilder, getImgProps } from 'images'
import { BackLink } from 'components/arrow-button'

// import 'assets/css/mdx.css'
// import 'assets/css/prose.css'

// import useArticleMdx from "../../hooks/useArticleMdx";

// import 'assets/css/mdx.css';
// import { responsiveFontSizes } from "@mui/material";

const Article = () => {
	const { state } = useLocation();
	const [article, setArticle] = useState(null);
	const [loading, setLoading] = useState(!article || false);
	const { tagList } = article || {};
	const { headers } = useAuth();
	const navigate = useNavigate();
	const { slug } = useParams();

	// const [mdx, setMdx] = useState({});
	// const [loading, setLoading] = useState(true);
	
    // const renderers = {
    //     //This custom renderer changes how images are rendered
    //     //we use it to constrain the max width of an image to its container
    //     image: ({
    //         alt,
    //         src,
    //         title,
    //     }) => (
    //         <img 
    //             alt={alt} 
    //             src={src} 
    //             title={title} 
    //             style={{ maxWidth: 10 }}  />
    //     ),
    // };
	// console.log(state)
	// useEffect(() => {
	// 	getBodyMdx({ slug, headers })
	// 		.then((resp) => {
	// 			console.dir(resp)
	// 			setMdx(resp)
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		})
	// 		.finally(() => setLoading(false));
			
	// 	console.dir(mdx)
	// }, []);
	useEffect(() => {
		// console.log(!article)
		if (article) {
			setArticle(article)
			setLoading(false)
			return;
		}
		setLoading(true)
		// console.log("state.mdx")

		getArticle({ slug, headers })
		.then(setArticle)
		.catch((error) => {
			console.error(error);
			navigate("/not-found", { replace: true });
		})
		.finally(() => setLoading(false));

	}, [slug, state, navigate]);
	// console.log(!article)
	let Komponent = null;
	// let frontmatter = {};
	// const { Komponent, frontmatter, loading } = useArticleMdx({ slug, headers, });
	// console.dir(state.mdx)
	// console.dir(state.mdx?.code)
	// console.dir(state)
	// if(loading || loading===undefined || !state) {
	// 	return (
	// 		<div className="article-page">
	// 			<Spinner size={16} showSpinner={loading || loading===undefined} />
	// 		</div>
	// 	)
	// }
	if(!loading && loading!==undefined && state) {
		if(state.mdx?.code && state.mdx.code!=="") {
			// console.dir(state.mdx.code)
			const Kcomponent = state.mdx ? mdxBundler.getMDXComponent(state.mdx.code) : null;
			Komponent = <Kcomponent key={slug} />
		}
		// const {frontmatter} = state.mdx || {};
	}
	// console.log(article)
	// console.log(loading)
	return	( 
		<Box sx={{bgcolor: 'background.default', color: 'text.primary', borderRadius: 1,	px: 0,}}>
			<Helmet>
				<meta name="description" content={article?.title || "Ekambuli High School"} />
				<title>{article?.title || "Ekambuli High School Blog"}</title>
				<meta name="og:url" content={window.location.href}></meta>
				<link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content={article?.title || "Ekambuli Secondary School Blog"}></meta>
				<meta name="og:description" content={article?.title || "Ekambuli Secondary School Blog"}></meta>
				<meta name="keywords" content={(article?.tagList?.join(", ") || "") + ", kenya, khwisero, luanda, kakamega, secondary, school, education, blog"}></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
			</Helmet>
			<DefaultNavbar
				routes={routes}
				relative
				sticky
			/>
			{ loading ? (
				<div className="article-page mx-7vw">
					<Spinner size={16} showSpinner={loading || loading===undefined} />
				</div>
			) : ( <>
				<div className="mx-7vw">
					{/* <BannerContainer>
						<h1>{frontmatter?.title || state.title}</h1>
						<ArticleMeta author={author} escapeHtml={false} createdAt={frontmatter?.date || state.createdAt} readTime={state.mdx.readTime}>
							<ArticlesButtons readTime={state.mdx?.readTime} article={article} setArticle={setArticle} />
						</ArticleMeta>
					</BannerContainer> */}
										{/* style={{border: 'solid', borderRadius: 15,  marginTop: 50, width: '100%'}} */}
						<div className="">
							<div className="col-span-full flex justify-between lg:col-span-8 lg:col-start-3 my-11">
								<BackLink to="/blog">Back to overview</BackLink>
							</div>
							<div className="col-md-12 text-xl">
								<H2 className="leading-tight text-3xl md:text-4xl text-black dark:text-white">{article.title}</H2>
								<H6 as="p" variant="secondary" className="mt-2 mb-11">
									{[formatDate(article.updated_at), article.readTime ?? 'quick read']
										.filter(Boolean)
										.join(' — ')}
								</H6>
								<p className="mb-11">
									<img
										title={article.title}
										{...getImgProps(
											getImageBuilder(images[article.bannerId].id, images[article.bannerId].alt,),
											{
												widths: [280, 560, 840, 1100, 1300, 1650],
												sizes: [
													'(max-width:639px) 80vw',
													'(min-width:640px) and (max-width:1023px) 40vw',
													'(min-width:1024px) and (max-width:1620px) 25vw',
													'420px',
												],
												transformations: {
													background: 'rgb:e6e9ee',
													resize: {
														type: 'fill',
														// aspectRatio: '3:4',
													},
												},
											},
										)}
										className="focus-ring w-full rounded-lg object-cover object-center transition"
										loading="lazy"
									/>
								</p>
								{/* {body && <Markdown options={{ forceBlock: true }}>{body}</Markdown>} */}
								{Komponent ? (
									Komponent
								) : (
									<Markdown options={{ forceBlock: true }} className="relative grid grid-cols-4 gap-x-4 md:grid-cols-8 lg:grid-cols-12 lg:gap-x-6 mx-auto max-w-7xl prose prose-light mb-24 break-words dark:prose-dark">{article?.body}</Markdown>
								)
								}
								<div className="w-full flex justify-end my-7">
									<ArticleTags tagList={tagList} />
								</div>
							</div>
						</div>
				</div>
				</>
			)}
		</Box>
	);
}

export default Article;
