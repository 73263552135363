import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import bgImage from "assets/images/drama-20240612.jpg";

function Approach({ refProps }) {
	return (
		<>
            <Box 
                ref={refProps}
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                        rgba(gradients.dark.main, 0.1),
                        rgba(gradients.dark.state, 0.1)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    fontFamily:"cursive",
                    paddingLeft:"5px"
                }}
                >
                <div className="text-[color:white] text-3xl md:text-4xl text-center text-bold ">
                    For best learning outcomes
                </div>
            </Box>
            <Box p={0} sx={{ maxWidth: '100%', flexGrow: 1, bgcolor:"inherit" }} className="-mt-12 mb-20 rounded-xl mx-5vw" >
                <Grid container spacing={2} >
                    <Grid item md={4} mt={-4}>
                        <div className="rounded-lg text-3xl py-1" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.55rem .938rem 0.55rem" }}>Our Approach</div>
                    </Grid>
                    <Grid item md={8}>
                        <Typography component={'p'} sx={{ mb:"1.75rem" }} >
                            We firmly believe that the best learning outcomes are achieved through intrinsic motivation and self-drive. 
                            We encourage students to join the boarding section for more teacher interaction. 
                            We have engaged a team of experienced examiners, implemented the use of examination cards, supported our teachers to train as examiners in various subjects and launched a thorough revision strategy, exposing student to past papers and mock exams from top schools. 
                        </Typography>
                        <div className="text-sch_color1 text-xl tracking-tighter">Our Motto</div>
                        <p className="tracking-tighter mb-7">
                            Effort Breeds Success.
                        </p>
                        <div className="text-sch_color1 text-xl tracking-tighter">Our Vision</div>
                        <p className="tracking-tighter mb-7">
                            To be a Center of Exellence.
                        </p>
                        <div className="text-sch_color1 text-xl tracking-tighter">Our Mission</div>
                        <p className="tracking-tighter mb-7">
                            To educate and nurture reliable members of society. <br/><br/>
                            We offer both the 8-4-4 and CBS education systems. <br/><br/>
                            Beyond academics, our school shapes our students through a variety of co-curricular activities. <br/>
                            We participate and compete at a high level in music (at the regional level), football, netball, volleyball, scouting, and more.<br/><br/>
                            We are proud of our dedicated team of teaching and non-teaching staff who work tirelessly to implement all school-planned activities. <br/><br/>
                            Guided by the principle &quot;Do unto others...,&quot; we believe that what we do for ourselves dies with us, but what we do for others endures even after we are gone. 
                            This belief drives our commitment to our students and their education.
                        </p>
                    </Grid>
                </Grid>
            </Box>
        </>
	);
}

export default Approach;