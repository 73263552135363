import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import getArticles from "../services/getArticles";

function useArticles({ location, tabName="global", tagName, username }) {
  const [{ articles, articlesCount }, setArticlesData] = useState({
    articles: [],
    articlesCount: 0,
  });
  const [loading, setLoading] = useState(true);
  // const { headers } = useAuth();
  const { headers = null } = useAuth() || {};

  useEffect(() => {
    // console.log(tagName, tabName)
    if (!headers && tabName === "feed") return;

    setLoading(true);
    // console.log(tagName)
    getArticles({ headers, location, tabName, tagName, username })
      .then(setArticlesData)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [headers, location, tabName, tagName, username]);
  // console.dir(articles)
  // const tagSet = new Set()
	// for (const article of articles) {
  //   for (const tag of article.tagList) {
  //     if(tag) tagSet.add(tag)
  //   }
	// }
  // const tags = Array.from(tagSet)

  return { articles, articlesCount, loading };
}


export default useArticles;
