/**
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/
import React from "react";
// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
// import materialIcons from "/node_modules/material-design-icons/iconfont/material-icons.css";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";

import {WhyUs} from "layouts/pages/landing-pages/why-us";
// import {Overview} from "layouts/pages/landing-pages/why-us";
// import {Approach} from "layouts/pages/landing-pages/why-us";
import {Achievements} from "layouts/pages/landing-pages/why-us";

import ContactUs from "layouts/pages/landing-pages/contact-us";
import Privacy from "layouts/pages/landing-pages/privacy";
// import Profile from "layouts/sections/components/Articles/routes/Profile/Profile";
// import ProfileArticles from "layouts/sections/components/Articles/routes/Profile/ProfileArticles";
// import ProfileFavArticles from "layouts/sections/components/Articles/routes/Profile/ProfileFavArticles";
// import SignIn from "layouts/pages/authentication/sign-in";
// import Settings from "layouts/sections/components/Articles/routes/Settings";
// import CommentsSection from "layouts/sections/components/Articles/routes/Article/CommentsSection";
import Login from "layouts/sections/components/Articles/routes/Login";
// import LogOut from "layouts/sections/components/Articles/components/LogOut";
import TermsOfUse from "layouts/pages/landing-pages/terms-of-use";
// import Blog from "layouts/pages/landing-pages/blog";
// import Article from "layouts/pages/landing-pages/article";
// import AppArticle from "layouts/sections/components/Articles/App";
// import BlogHome from "layouts/sections/components/Articles/routes/BlogHome";
// import ArticleEditor from "layouts/sections/components/Articles/routes/ArticleEditor";

import BlogApp from "layouts/sections/components/Articles/BlogApp"
import Article from "layouts/sections/components/Articles/routes/Article/Article"
// import { useAuth } from "layouts/sections/components/Articles/context/AuthContext";
// import AuthProvider from "layouts/sections/components/Articles/context/AuthContext";
// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";
// import './assets/css/css2.css';

// const refAchieve = React.useRef();

const routes = [
	// {
	// 	name: "Why Ekambuli",
	// 	key: "why us",
	// 	icon: <Icon>face</Icon>,		//<i className="material-icons">face</i>,
	// 	route: "/why-us",
	// 	component: <WhyUs />,
	// },
	{
		name: "why us",
		icon: <Icon>article</Icon>,
		collapse: [
			{
				name: "Overview",
				key: "overview",
				invisible: true,
				description: "Educational excellence in Western Kenya",
				route: "/why-us",
				// rHash: "overview",
				component: <WhyUs ttl="Overview" descr="Learning overview"/>,
			},
			{
				name: "Our Approach",
				key: "approach",
				invisible: false,
				description: "Intrinsic motivation and self-drive",
				route: "/why-us",
				component: <WhyUs ttl="Our Approach" descr={"Our approach"} />,
			},
			{
				name: "Our Achievements",
				key: "achieve",
				invisible: false,
				description: "Well-rounded children",
				route: "/why-us",
				component: <Achievements ttl="Our achievements" descr="Our achievements"/>,
			},
		],
	},
	{
		key: "contact us",
		name: "contact us",
		invisible: false,
		icon: <Icon>call</Icon>,
		route: "/contact-us",
		component: <ContactUs ttl="Contact us" descr="Contact us"/>,
	},
	{
		name: "about us",
		key: "about us",
		invisible: false,
		icon: <Icon>people</Icon>,
		route: "/about-us",
		component: <AboutUs ttl="About us" descr="About us"/>,
	},
	{
		name: "privacy policy",
		key: "privacy policy",
		invisible: true,
		icon: <Icon>policy</Icon>,
		route: "/privacy-policy",
		component: <Privacy ttl="Privacy" descr="Privacy" />,
	},
	{
		name: "terms of use",
		key: "terms of use",
		invisible: true,
		icon: <Icon>terms</Icon>,
		route: "/terms-of-use",
		component: <TermsOfUse ttl="Privacy" descr="Terms of use" />,
	},
	// {
	// 	name: "blog",
	// 	key: "blog",
	// 	invisible: false,
	// 	icon: <Icon>blog</Icon>,
	// 	route: "/blog",
	// 	component: <Blog />,
	// },
	// {
	// 	name: "blog",
	// 	key: "blog",
	// 	invisible: true,
	// 	icon: <Icon>blog</Icon>,
	// 	route: "/blog/:slug",
	// 	component: <Article />,
	// },
	{
		key: "signin",
		name: "sign in",
		invisible: true,
		icon: <Icon>lockopen</Icon>,
		route: "/sign-in",
		component: <Login />,
	},
	{
		key: "blog",
		name: "Blog",
		invisible: false,
		icon: <Icon>article</Icon>,
		route: "/blog",
		component: <BlogApp ttl="Blog" descr="Our blog" />,
	},
	{
		key: "article",
		name: "Article",
		invisible: true,
		icon: <Icon>article</Icon>,
		route: "/blog/:slug",
		component: <Article ttl="Article" descr="Our Article" />,
	},
	// {
	// 	invisible: false,
	// 	name: "mode",
    //     icon: <Icon>lockopen</Icon>,
    //     loader: modeLoader,
    // },
		// collapse: [
		// 	{
		// 		name: "New Article",
		// 		key: "new-article",
		// 		icon: <Icon>ion-compose</Icon>,
		// 		invisible: false,
		// 		route: "/blog/editor",
		// 		component: <ArticleEditor />,
		// 	},
			// {
			// 	key: "blog",
			// 	// name: "Blog",
			// 	invisible: false,
			// 	icon: <Icon>article</Icon>,
			// 	route: "/blog",
			// 	component: <Blog />,
			// },
			// {
			// 	name: "Settings",
			// 	key: "settings",
			// 	icon: <Icon>ion-gear-a</Icon>,
			// 	invisible: false,
			// 	// description: "User Settings",
			// 	route: "/blog/settings",
			// 	component: <Settings />,
			// },
			// {
			// 	name: "Sign Out",
			// 	key: "logout",
			// 	icon: <Icon>ion-log-out</Icon>,
			// 	invisible: false,
			// 	// description: "Log out",
			// 	route: "/sign-out",
			// 	component: <LogOut />,
			// },
			// {
			// 	name: "Sign In",
			// 	key: "signin",
			// 	icon: <Icon>ion-log-in</Icon>,
			// 	invisible: false,
			// 	// description: "Sign out",
			// 	route: "/sigin-in",
			// 	component: <Login />,
			// },
			// {
			// 	key: "profile",
			// 	name: "Profile",
			// 	invisible: true,
			// 	icon: <Icon>ion-person</Icon>,
			// 	route: "/blog/profile/",
			// 	component: <Profile />,
			// },
			// {
			// 	key: "profilearticles",
			// 	name: "Profile Articles",
			// 	invisible: true,
			// 	icon: <Icon>article</Icon>,
			// 	route: "/editor",
			// 	component: <ProfileArticles />,
			// },
			// {
			// 	key: "profilefavarticles",
			// 	name: "Profile Favorite Articles",
			// 	invisible: true,
			// 	icon: <Icon>article</Icon>,
			// 	route: "/profile/",
			// 	component: <ProfileFavArticles />,
			// },
			// {
			// 	key: "blog-article",
			// 	name: "Article",
			// 	invisible: true,
			// 	icon: <Icon>article</Icon>,
			// 	route: "/article/:slug",
			// 	component: <Article />,
			// },
			// {
			// 	key: "commentsSection",
			// 	name: "Comments Section",
			// 	invisible: true,
			// 	icon: <Icon>article</Icon>,
			// 	route: "/blog/article/:slug/comments",
			// 	component: <CommentsSection />,
			// },
			
		// ],
	// },
	// {
	// 	key: "blog-article",
	// 	name: "Article",
	// 	invisible: true,
	// 	icon: <Icon>article</Icon>,
	// 	route: "/blog/articles",
	// 	component: <Article />,
	// },
	// {
	// 	key: "comments",
	// 	name: "comments",
	// 	invisible: true,
	// 	icon: <Icon>lockopen</Icon>,
	// 	route: "/articles/",
	// 	component: <Home />,
	// },
];
export default routes;
